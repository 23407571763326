function path(root, sublink) {
    return `${root}${sublink}`;
}
const ROOTS_DASHBOARD = '/';
// ----------------------------------------------------------------------
export const PATH_AUTH = {
    login: '/login',
    register: '/register',
    resetPassword: '/reset-password',
    permissionsDenied: '/403',
    emailVerification: (email) => `/confirm-account/${email}`,
    resetPasswordWithEmail: (email) => `/reset-password/${email}`,
};
export const PATH_DASHBOARD = {
    dashboard: {
        root: '/dashboard',
    },
    finance: {
        root: '/finance',
        invoice: '/finance/invoices',
        newInvoice: '/finance/invoices/new',
        viewInvoice: (id) => `/finance/invoices/${id}`,
        editInvoice: (id) => `/finance/invoices/${id}/edit`,
        expense: '/finance/expenses',
        newExpense: '/finance/expenses/new',
        viewExpense: (id) => `/finance/expenses/${id}`,
        editExpense: (id) => `/finance/expenses/${id}/edit`,
        accounts: {
            root: '/finance/accounts',
            bankAccounts: '/finance/accounts/bank-accounts',
            newBankAccount: '/finance/accounts/bank-accounts/new',
            editBankAccount: (id) => `/finance/accounts/bank-accounts/${id}/edit`,
            viewBankAccount: (id) => `/finance/accounts/bank-accounts/${id}`,
            branchCashboxes: '/finance/accounts/branch-cashboxes',
            newBranchCashbox: '/finance/accounts/branch-cashboxes/new',
            viewBranchCashbox: (id) => `/finance/accounts/branch-cashboxes/${id}`,
            editBranchCashbox: (id) => `/finance/accounts/branch-cashboxes/${id}/edit`,
        },
    },
    permissionGroups: {
        root: '/permission-groups',
    },
    branches: {
        root: '/branches',
        new: '/branches/new',
        view: (id) => `/branches/${id}`,
        edit: (id) => `/branches/${id}/edit`,
    },
    fleet: {
        root: path(ROOTS_DASHBOARD, 'fleet'),
        classes: path(ROOTS_DASHBOARD, 'fleet/classes'),
        class: '/fleet/classes',
        newCategory: '/fleet/classes/new',
        editClass: (id) => `/fleet/classes/${id}/edit`,
        viewClass: (id) => `/fleet/classes/${id}`,
        leasingContracts: '/fleet/leasingContracts',
        rentables: path(ROOTS_DASHBOARD, 'fleet/rentals'),
        createVehicle: path(ROOTS_DASHBOARD, 'fleet/rentals/new'),
        edit: (name) => path(ROOTS_DASHBOARD, `fleet/rentals/edit/${name}`),
        show: (name) => path(ROOTS_DASHBOARD, `fleet/rentals/show/${name}`),
        rentableTypes: '/fleet/rentable-types',
        createRentableType: '/fleet/rentable-types/new',
        editRentableType: (id) => `/fleet/rentable-types/${id}/edit`,
        viewRentableType: (id) => `/fleet/rentable-types/${id}`,
        rentableExtraTypes: '/fleet/rentable-extra-types',
        createRentableExtraType: '/fleet/rentable-extra-types/new',
        editRentableExtraType: (id) => `/fleet/rentable-extra-types/${id}/edit`,
        viewRentableExtraType: (id) => `/fleet/rentable-extra-types/${id}`,
        brands: '/fleet/brands',
        createBrand: '/fleet/brands/new',
        editBrand: (id) => `/fleet/brands/${id}/edit`,
        viewBrand: (id) => `/fleet/brands/${id}`,
        models: '/fleet/models',
        createModel: '/fleet/models/new',
        editModel: (id) => `/fleet/models/${id}/edit`,
        viewModel: (id) => `/fleet/models/${id}`,
    },
    pricing: {
        root: '/pricing',
        pricingGroups: '/pricing/pricing-groups',
        newPricingGroup: '/pricing/pricing-groups/new',
        viewPricingGroup: (id) => `/pricing/pricing-groups/${id}`,
        editPricingGroup: (id) => `/pricing/pricing-groups/${id}/edit`,
        seasons: '/pricing/seasons',
        newSeason: '/pricing/seasons/new',
        viewSeason: (id) => `/pricing/seasons/${id}`,
        editSeason: (id) => `/pricing/seasons/${id}/edit`,
    },
    reservations: {
        root: '/reservations',
        list: '/reservations/list',
        rejected: '/reservations/rejected',
        viewReservation: (id) => `/reservations/${id}`,
        editReservation: (id) => `/reservations/${id}/edit`,
        calendar: '/reservations/calendar',
        viewContract: (id) => `/reservations/contract/${id}`,
        newReservation: '/reservations/new',
        pickup: '/reservations/pickup',
    },
    customers: { root: '/customers', viewCustomer: (id) => `/customers/${id}` },
    settings: {
        root: '/settings',
        agency: '/settings/agency',
        newAgency: '/settings/agency/new',
        editAgency: (id) => `/settings/agency/${id}/edit`,
        viewAgency: (id) => `/settings/agency/${id}`,
        expenseConfigs: '/settings/expense-configs',
        newExpenseConfig: '/settings/expense-configs/new',
        editExpenseConfig: (id) => `/settings/expense-configs/${id}/edit`,
        viewExpenseConfig: (id) => `/settings/expense-configs/${id}`,
        users: '/settings/users',
        editUser: (id) => `/settings/users/${id}/edit`,
        viewUser: (id) => `/settings/users/${id}`,
        newUser: '/settings/users/new',
    },
    affiliations: {
        root: '/affiliations',
        new: '/affiliations/new',
        view: (id) => `/affiliations/${id}`,
        edit: (id) => `/affiliations/${id}/edit`,
    },
};
